export interface Toast {
  source: string;
  success: boolean;
  message: string;
}

export function isToast(value: unknown): value is Toast {
  return (
    typeof value === "object" &&
    value !== null &&
    "source" in value &&
    "success" in value &&
    "message" in value &&
    typeof value.source === "string" &&
    typeof value.success === "boolean" &&
    typeof value.message === "string"
  );
}
